import { useDispatch } from 'react-redux';
import { formControlActions } from 'store/formControl/formControlSlice';
import React from 'react';
import { useEffect } from 'react';
import AIQAutocomplete from '../AIQAutocomplete/AIQAutocomplete';

import AIQInput from '../AIQInput/AIQInput';
import { useCallback } from 'react';
// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function Select({
    fieldName,
    initialValue = null,
    value,
    label,
    touched,
    type,
    error,
    formId,
    required,
    lookup,
    disabled,
    blurHandler = () => {},
    changeHandler = () => {},
    inputValueChangeHandler = () => {},
    automation,
    validationField,
    referenceField,
    referenceValue,
    validationStyles,
    containerClasses,
    forceFieldRegistration,
    helperText,
}) {
    const backgroundColor = validationStyles?.color ? { backgroundColor: validationStyles?.color } : null;

    const dispatch = useDispatch();
    useEffect(() => {
        if (!formId) return;
        dispatch(
            formControlActions.registerNewFormField({
                formId,
                fieldName,
                initialValue,
                disabled: disabled && !forceFieldRegistration,
                required,
            })
        );
        if (required && initialValue) {
            dispatch(
                formControlActions.setFieldError({
                    formId,
                    fieldName,
                    error: false,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, fieldName, formId, initialValue, required, forceFieldRegistration]);

    const getOptionLabel = useCallback(
        function (option) {
            if (lookup?.displayExpr) return option?.[lookup.displayExpr];
        },
        [lookup?.displayExpr]
    );

    const hasError = touched && error; // && required;

    const acValue =
        lookup?.dataSource.find((data) => {
            let activeOption = false;
            if (data?.[lookup.displayExpr]) activeOption = data?.[lookup.displayExpr] === value;
            if (data?.[lookup.valueExpr]) activeOption = data?.[lookup.valueExpr] === value;
            if (referenceField && referenceValue) {
                activeOption = data?.[lookup.referenceExpr] === referenceValue;
            }

            return activeOption;
        }) ?? value;

    return (
        <AIQAutocomplete
            valueExpr={lookup?.valueExpr}
            getOptionLabel={lookup?.displayExpr && getOptionLabel}
            value={acValue || null}
            onInputChange={inputValueChangeHandler.bind({
                lookup,
                formId,
                fieldName,
                type,
                error,
                required,
                referenceField,
                referenceValue,
            })}
            onChange={changeHandler.bind({
                lookup,
                formId,
                fieldName,
                type,
                error,
                required,
                referenceField,
            })}
            onBlur={blurHandler.bind({
                required,
                touched,
                error,
                formId,
                fieldName,
                validationField,
            })}
            freeSolo={!lookup?.displayExpr || referenceField}
            options={lookup?.dataSource}
            containerClasses={containerClasses}
            renderInput={(params) => {
                return (
                    <AIQInput
                        {...params}
                        helperText={`${helperText && hasError ? helperText : ''} `}
                        label={label}
                        type={type}
                        error={hasError}
                        name={fieldName}
                        InputLabelProps={{ shrink: true }}
                        automation={automation}
                        disabled={disabled}
                        {...backgroundColor}
                    />
                );
            }}
        />
    );
}

export default React.memo(Select);
