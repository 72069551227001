import { MenuItem, FormControl, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import React, { useCallback, useEffect, useMemo } from 'react';
import { LightInputLabel, LightSelect } from './GlobalLocationSelector.styled.js';
import useGlobalLocation from 'components/globalLocationSelector/hooks/useGlobalLocation.js';

const GlobalLocationSelector = () => {
    const { locationsList, fetchLocations, fetchingLocationsInProgress, selectedLocationId, setSelectLocationId } = useGlobalLocation();
    const locationOpts = useMemo(() => {
        const options =
            locationsList?.map((location) => ({
                value: location.id,
                label: location.yardName,
            })) || [];
        return options;
    }, [locationsList]);

    const handleLocationChange = useCallback(
        (event) => {
            setSelectLocationId(event.target.value);
        },
        [setSelectLocationId]
    );

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    return (
        <Stack direction="row" gap={2} flexGrow={1}>
            <FormControl>
                <LightInputLabel shrink={true}>Location</LightInputLabel>
                <LightSelect
                    notched={true}
                    label="Location"
                    size="small"
                    variant="outlined"
                    disabled={fetchingLocationsInProgress}
                    value={selectedLocationId || ''}
                    onChange={handleLocationChange}
                >
                    {locationOpts.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            {opt.label}
                        </MenuItem>
                    ))}
                </LightSelect>
            </FormControl>
            <Box />
        </Stack>
    );
};

export default GlobalLocationSelector;
