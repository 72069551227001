import SvgIcon from '@mui/material/SvgIcon';

const Tollbooth = () => {
    return (
        <SvgIcon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" fill="none" />
                <rect width="24" height="24" fill="url(#pattern0)" />
                <defs>
                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlinkHref="#image0" transform="scale(0.0111111)" />
                    </pattern>
                    <image
                        id="image0"
                        width="90"
                        height="90"
                        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAADmklEQVR4nO2cvWsUQRjGx280phGx8gNFTJ3K8lA4PHXnefYOprCxELky/0JALSSKGI2Y2j9AETvLWNlaaeVnQC2ijZ8okblsIJx3t7vJ7s7O5P3BC+Eg+87+eHZmbrg7pQRBEARBEARB8I5Op3PElutxBEsURYdJzpL8CeA3gAda6+OuxxWkYJLLa0uElyyYIrxawRTh1QqmCK9WMEV4tYIpwqsVzM0o3KVgbgbhrVZrF8lb9uZcC+YA4XZsdozKdwDcdC2U6cJvKN8B8Nm1SKbXR+U7AD7VQORy8KJJzngwdVxXvtPtdnfYObCOycbKmGYajcZ2FRKuxbKvVKi4FksRLaILxXWCKYkW0YXiOsGURI8U8hbAHZJnAEw0m80xW/Zv+xrJuwDeiej1J/pDHMddY8y2tCdlenp6KwAD4LUkOodoAI+MMXvzTk0Axkk+lqkjm+jbNqF5Ja9Nd3LuLXP0qCRvRHKf7NRkq1BJmS7er2e6SJlGFkX0/6IvqYIhebmOoqMo2q+17pTWYNQWLsvuIi/2mvZJqYtoe0oIYIrkEoBfcRyfqFr0bCkNVa/nXB1Ea61PA3jRN4anpTQbdsNa61YpDVWv57k6iB6xNp2vrFlpj5DqLYoTLkTbd68ZRb8yxuwstPmIZuOqJLCy+6hS9JY4ji/YtSHjvf+xRwmFjsDVI8yK+gKYBLAw6PpDJD8HcLLocQQr2hizzy7oSToHXr+v9yKAizb9qgxCFQ3gS9r1k9d+kLzaP38XTqiimeH6JB9qrY8W1XPDA6p7X5KH7Acky7q+2uyim83mGMlryeOfutDZd3/KFb6KJqkBvMmy0AH4axPfbrcPlHIzoYoG8CzjQmclL9gtnnKNj6KZbaErd7sWmuhGcrqW939L366FJBrAqdXTtTqMOTjR7Xb7oF28six0Sb2M4/isqjPDbthVAbiyul3LINoe1k958RFf12KZsdaOuRbbtbx4KnpS+YaPor3EtUCmV+90TflODUQuDysATzqdzjEVAq5lcnDVf7uWlxqmeMqL7VpeXItlX6lQcS2WIlpEF4rrBFMSLaILxXWCKYkW0YXiOsGURIvoQnGdYEqiRXShuE4wJdEiesNEUbQHwD0AX10nmH2VfNx2zhizW/kOgHnXQpkufF75TPKrA99di2R6fSviq9HOENEV4sPUQfK+8h270CS/EjPwex6Oa8n+uk0Qi6EgCIIgCIIgCIKqiH/vwZRj1Lx/2QAAAABJRU5ErkJggg=="
                    />
                </defs>
            </svg>
        </SvgIcon>
    );
};

export default Tollbooth;
