import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Navigation.module.css';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import DrawerListItem from './DrawerListItem/DrawerListItem';
import UserNavigation from 'components/lorriniCompnents/navigation/userNavigation/UserNavigation';
import birdLogo from 'assets/BirdsEyeLogo.svg';
import { useTopNavRouteData } from 'components/appRoute/hooks/useTopNavRouteData.js';

function NavigationDrawer({ user }) {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawerOpenHandler = () => setDrawerOpen((state) => !state);

    const topNavRouteData = useTopNavRouteData();

    return (
        <div className={styles.navigation}>
            <div className={styles.leftSection}>
                <IconButton onClick={toggleDrawerOpenHandler}>
                    <MenuIcon sx={{ color: 'white' }} />
                </IconButton>
                <Link className={styles.logo} to={'/'}>
                    <img src={birdLogo} alt="BirdsEyeLogo" />
                </Link>
            </div>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawerOpenHandler}>
                <List sx={{ paddingTop: 'var(--header__height--large)' }}>
                    <DrawerListItem user={user} toggleDrawer={toggleDrawerOpenHandler} />
                    <Divider />
                </List>
            </Drawer>
            {topNavRouteData.topNavMiddleAdornment}
            <UserNavigation user={user} />
        </div>
    );
}

export default NavigationDrawer;

NavigationDrawer.propTypes = {
    user: PropTypes.object,
};
