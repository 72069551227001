import { createBrowserRouter, createRoutesFromElements, Route, Routes } from 'react-router-dom';
import Redirect from 'pages/Redirect/Redirect.jsx';
import ProtectedRoute from 'components/protectedRoute/ProtectedRoute.jsx';
import { ROLES } from 'utils/constants.js';
import ControlCentar from 'pages/ControlCentar/controlCentar.jsx';
import CameraInfo from 'pages/CameraInfo/CameraInfo.jsx';
import MonitoringObject from 'pages/MonitoringObjectPage/MonitoringObject.jsx';
import NoPage from 'pages/NoPage.jsx';
import React from 'react';
import AppLayout from 'components/layout/AppLayout.jsx';
import GlobalLocationSelector from 'components/globalLocationSelector/GlobalLocationSelector.jsx';

const EmployeePage = React.lazy(() => import('pages/EmployeePage/EmployeePage'));
const PeopleAuthorizedListPage = React.lazy(() => import('pages/PeopleAuthorizedListPage/PeopleAuthorizedListPage'));
const MonitoringElements = React.lazy(() => import('pages/MonitoringElementsPage/MonitoringElements'));
const CompanyAuthorizedListPage = React.lazy(() => import('pages/CompanyAuthorizedListPage/CompanyAuthorizedListPage'));
const OrganizationListPage = React.lazy(() => import('pages/OrganizationListPage/OrganizationListPage'));
const DivisionListPage = React.lazy(() => import('pages/DivisionList/DivisionList'));
const LocationListPage = React.lazy(() => import('pages/LocationListPage/LocationListPage'));
const HomePage = React.lazy(() => import('pages/HomePage'));
const RegionsOfInterest = React.lazy(() => import('pages/RegionsOfInterest/RegionsOfInterest'));
const AccessPointRecords = React.lazy(() => import('pages/AccessPointRecords/AccessPointRecords'));

export const createAppRoute = (user) => {
    const hasUserRole = (role) => user?.roles?.includes(role) || user?.roles?.includes(ROLES.ADMIN) || user?.roles?.includes(ROLES.USER);
    return createBrowserRouter(
        createRoutesFromElements(
            <Route element={<AppLayout />}>
                <Route path="/redirect" element={<Redirect user={user} />} />
                <Route element={<ProtectedRoute isAllowed={!!user} redirectPath="/redirect" />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/regions-of-interest" element={<RegionsOfInterest user={user} />} />
                    <Route element={<ProtectedRoute isAllowed={hasUserRole(ROLES.HR)} />}>
                        <Route path="/employees" element={<EmployeePage />} />
                    </Route>
                    <Route element={<ProtectedRoute isAllowed={hasUserRole(ROLES.AGENT)} />}>
                        <Route path="/people-authorized-list" element={<PeopleAuthorizedListPage />} />
                        <Route path="/company-authorized-list" element={<CompanyAuthorizedListPage />} />
                    </Route>
                    <Route element={<ProtectedRoute isAllowed={hasUserRole(ROLES.HQ_SUPPORT) || hasUserRole(ROLES.SALES)} />}>
                        <Route path="/organization-list" element={<OrganizationListPage />} />
                        <Route path="/location-list" element={<LocationListPage />} />
                        <Route path="/divison-list" element={<DivisionListPage />} />
                    </Route>
                    <Route element={<ProtectedRoute isAllowed={hasUserRole(ROLES.TECH)} />}>
                        <Route path="/monitoring-elements" element={<MonitoringElements />} />
                        <Route path="/control-center" element={<ControlCentar />} />
                        <Route path="/camera-tron" element={<CameraInfo />} />
                        <Route path="/monitoring-object" element={<MonitoringObject />} />
                    </Route>
                    <Route element={<ProtectedRoute isAllowed={hasUserRole(ROLES.FACILITY_MANAGER)} />}>
                        <Route
                            path="/access-point-records"
                            element={<AccessPointRecords />}
                            handle={{
                                topNavMiddleAdornment: <GlobalLocationSelector />,
                            }}
                        />
                    </Route>
                </Route>
                <Route path="*" element={<NoPage />} />
            </Route>
        )
    );
};
