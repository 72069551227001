import React from 'react';
import styles from './Label.module.css';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function Label({ focused, id, label, error }, ref) {
    const labelStyles = `${styles['input-label']}${
        focused ? ` ${styles['input-label--focus']}` : ''
    }${error ? ` ${styles['input-label--error']}` : ''}`;

    return (
        <label className={labelStyles} htmlFor={id} ref={ref}>
            {label}
        </label>
    );
}

export default React.forwardRef(Label);
