import { styled } from '@mui/material/styles';
import { InputLabel, Select } from '@mui/material';

export const LightSelect = styled(Select)(({ theme }) => ({
    minWidth: '15rem',
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.contrastText,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.contrastText,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText,
    },
    '& .MuiFormLabel-root': {
        color: theme.palette.primary.contrastText,
    },
}));

export const LightInputLabel = styled(InputLabel)(({ theme }) => ({
    '&.MuiFormLabel-root': {
        color: theme.palette.primary.contrastText,
    },
}));
