import styles from './App.module.css';
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { sendLocalStorageSignal, sessionStorageTransferHandler } from 'utils/sessionStorageHandlers';
import { alertService } from 'services/AlertService';
import { getCookie } from 'utils/utils';
import { PROFILE } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUserDataRequested, selectUser } from 'store/auth/authSelectors';
import { authActions } from 'store/auth/authSlice';
import LinearLoader from 'components/UI/loaders/LinearLoader';
import { createAppRoute } from 'components/appRoute/AppRoute.jsx';

function App() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const isUserDataRequested = useSelector(selectIsUserDataRequested);

    const removeCookie = () => {
        switch (PROFILE) {
            case 'development':
                document.cookie = 'lastaD=AlertsON; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                break;
            case 'staging':
                document.cookie = 'lastaS=AlertsON; path=/; domain=.birdseyesecurity.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                break;
            case 'production':
                document.cookie = 'lastaP=AlertsON; path=/; domain=.birdseyesecurity.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                break;
            default:
                document.cookie = 'lasta=AlertsON; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    };
    useEffect(() => {
        window.addEventListener('storage', sessionStorageTransferHandler);

        let cookieLasta;
        let cookieSso;
        let cookieAiq;
        let cookieAlert;
        let cookieGu;

        switch (PROFILE) {
            case 'development':
                cookieLasta = getCookie('lastaD');
                cookieSso = getCookie('ssoD');
                cookieAiq = getCookie('aiqD');
                cookieAlert = getCookie('alertD');
                cookieGu = getCookie('guD');
                break;
            case 'staging':
                cookieLasta = getCookie('lastaS');
                cookieSso = getCookie('ssoS');
                cookieAiq = getCookie('aiqS');
                cookieAlert = getCookie('alertS');
                cookieGu = getCookie('guS');
                break;
            case 'production':
                cookieLasta = getCookie('lastaP');
                cookieSso = getCookie('ssoP');
                cookieAiq = getCookie('aiqP');
                cookieAlert = getCookie('alertP');
                cookieGu = getCookie('guP');
                break;
            default:
                cookieLasta = getCookie('lasta');
                cookieSso = getCookie('sso');
                cookieAiq = getCookie('aiq');
                cookieAlert = getCookie('alert');
                cookieGu = getCookie('gu');
        }

        if (cookieSso || cookieAiq || cookieAlert || cookieGu || cookieLasta) {
            alertService.activate();
            return;
        } else {
            switch (PROFILE) {
                case 'development':
                    document.cookie = 'lastaD=AlertsON; path=/; ';
                    break;
                case 'staging':
                    document.cookie = 'lastaS=AlertsON; path=/; domain=.birdseyesecurity.com; ';
                    break;
                case 'production':
                    document.cookie = 'lastaP=AlertsON; path=/; domain=.birdseyesecurity.com; ';
                    break;
                default:
                    document.cookie = 'lasta=AlertsON; path=/; ';
            }
            alertService.activate();
        }

        window.addEventListener('beforeunload', removeCookie);
        return () => {
            window.removeEventListener('beforeunload', removeCookie);
            alertService.deactivate();
            window.removeEventListener('storage', sessionStorageTransferHandler);
        };
    }, []);

    useEffect(() => {
        if (!user) {
            !sessionStorage?.length && sendLocalStorageSignal('GetSessionStorage');
        }
    }, [dispatch, user]);

    useEffect(() => {
        !user && dispatch(authActions.getUserData());
    }, [user, dispatch]);

    if (!isUserDataRequested)
        return (
            <div className={styles['cheking-permission']}>
                <p>Checking permissions...</p>
                <LinearLoader containerStyles={{ position: 'relative' }} />
            </div>
        );

    const router = createAppRoute(user);
    return <RouterProvider router={router} />;
}

export default App;
