import { all, call } from 'redux-saga/effects';
import { employeeSaga } from './employees/employeeSaga';
import { peopleAuthorizedListSaga } from './peopleAuthorizedList/peopleAuthorizedListSagas';
import { companyAuthorizationSaga } from './companyAuthorizedList/companyAuthorizationListSaga';
import { tableControlSaga } from './tableControlSlice/tableControlSagas';
import { cameraInfoSaga } from './cameraInfo/cameraInfoSaga';
import { organizationListSaga } from './organizationList/organizationListSaga';
import { locationListSaga } from './locationList/locationListSaga';
import { divisionListSaga } from './divisionList/divisionListSaga';
import { monitoringElementSaga } from './MonitoringElement/monitoringElementSaga';
import { controlCentarSaga } from './controlCentar/controlCentarSaga';
import { monitoringObjectSaga } from './monitoringObject/monitoringObjectSaga';
import { authSaga } from './auth/authSagas';
import { globalLocationSaga } from 'components/globalLocationSelector/store/globalLocationSaga.js';

export default function* rootSaga() {
    yield all([
        call(employeeSaga),
        call(peopleAuthorizedListSaga),
        call(tableControlSaga),
        call(authSaga),
        call(companyAuthorizationSaga),
        call(cameraInfoSaga),
        call(organizationListSaga),
        call(locationListSaga),
        call(divisionListSaga),
        call(monitoringElementSaga),
        call(controlCentarSaga),
        call(monitoringObjectSaga),
        call(globalLocationSaga),
    ]);
}
