import { call, put, select } from 'redux-saga/effects';
import { selectFormById } from 'store/formControl/formControlSelectors';
import { formControlActions } from 'store/formControl/formControlSlice';
import store from 'store/store';
import notificationsHandler from 'utils/notificationsHandler';
import { axiosLasta } from 'config';

export function* FormLocationSaga({ payload: type }) {
    try {
        const formId = type.formId;
        const {
            form: { values, errors },
        } = yield select(selectFormById(formId));
        let isFormValid = true;

        Object.keys(errors).forEach((fieldName) => {
            if (errors[fieldName]) {
                isFormValid = false;
                store.dispatch(formControlActions.setFieldTouched({ formId, fieldName }));
            }
        });

        if (!isFormValid) {
            yield notificationsHandler({
                title: 'Please fill required fields',
                variant: 'warning',
            });
            return;
        }

        let message;

        if (type.formStatus === 'add') {
            let data = {
                ...values,
                organization: {
                    id: values.organization,
                },
                active: true,
            };
            if (!data.division) {
                delete data.division;
            }
            if (values.division) {
                data.division = {
                    id: values.division,
                };
            }
            delete data.name;

            const response = yield axiosLasta.post(type.postUrl, data);
            ({ message } = response.data);
        }
        if (type.formStatus === 'edit') {
            const beforeEditData = type.beforeEditData;

            const hasChanged = Object.keys(values).reduce((hasChanged, fieldName) => {
                let setHasChanged = false;

                if (typeof values[fieldName] === 'object' && values[fieldName]?.name !== beforeEditData[fieldName]?.name) {
                    setHasChanged = true;
                }

                if (
                    (typeof values[fieldName] === 'string' ||
                        typeof values[fieldName] === 'number' ||
                        values[fieldName] === null ||
                        values[fieldName] === true ||
                        values[fieldName] === false) &&
                    values[fieldName] !== beforeEditData[fieldName]
                ) {
                    setHasChanged = true;
                }

                return !hasChanged ? setHasChanged : true;
            }, false);

            if (!hasChanged) {
                yield call(notificationsHandler, {
                    title: 'No changes detected',
                    variant: 'info',
                });
                return;
            }

            let data = {
                ...values,
                organization: {
                    id: values.organization,
                },
            };
            if (!data.division) {
                delete data.division;
            }
            if (values.division) {
                data.division = {
                    id: values.division,
                };
            }
            delete data.name;
            const response = yield axiosLasta.put(type.putUrl, {
                ...data,
                id: beforeEditData.id,
            });
            ({ message } = response.data);
        }

        if (message.includes('already exist')) {
            yield put(
                formControlActions.setFieldError({
                    formId,
                    fieldName: type.fields.name.fieldName,
                    error: `Location already exists`,
                })
            );
            return;
        }

        if (message.includes('SUCCESS')) {
            yield put(type.actions.setFormStatus('viewOnly'));
            yield put(type.actions.fetchMasterListStart());
            yield put(type.actions.setAddPopupVisible(false));

            yield notificationsHandler({
                title: `SUCCESSFULLY ${type.formStatus === 'edit' ? 'EDITED' : 'ADDED'} LOCATION`,
                variant: 'success',
            });
        }
    } catch (err) {
        yield notificationsHandler({ title: 'Error submitting data!' });
        yield put(type.actions.submitFormFailed(JSON.stringify(err.message)));
    }
}
