import { ListItemButton, Icon } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';
import classes from './Drawer.module.css';
import Tollbooth from 'components/icons/Tollbooth';

const DrawerItem = ({ route, onClick }) => {
    return (
        <ListItemButton component={Link} to={route.path} onClick={onClick} className={classes.item}>
            <div className={`${classes.item} ${route.disabled && classes.item__disabled}`}>
                <Icon disabled={route.disabled}>
                    {route.Icon && <route.Icon />}
                    {route.path === '/access-point-records' && <Tollbooth />}
                </Icon>

                <div>{route.text}</div>
                {route.items && <ArrowForwardIosIcon className={classes.arrow} />}
            </div>
        </ListItemButton>
    );
};

export default DrawerItem;

DrawerItem.propTypes = {
    route: PropTypes.object,
    onClick: PropTypes.func,
};
