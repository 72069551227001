import { axiosLasta } from 'config';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import notificationsHandler from 'utils/notificationsHandler';
import { FormSaga } from 'store/formControl/formPeopleEmployeeSaga';

import { controlCentarActions } from 'store/controlCentar/controlCentarSlice';
import { peopleAuthorizedListActions } from './peopleAuthorizedListSlice';

export function* fetchAllLocations() {
    try {
        const response = yield axiosLasta('/location/all');
        const { data } = response;
        data.sort(function (a, b) {
            var textA = a?.yardName?.toUpperCase();
            var textB = b?.yardName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        yield put(peopleAuthorizedListActions.setAllLocations(data));
        yield put(controlCentarActions.setLocationValues(data));
    } catch (err) {
        yield notificationsHandler({ err, title: 'error fetching locations' });
    }
}

export function* onFetchAllLocations() {
    yield takeEvery(peopleAuthorizedListActions.fetchAllLocations.type, fetchAllLocations);
}

export function* fetchAuthorizedPersonPerLocation({ payload: location }) {
    try {
        const modifiedAuthorizedList = [];
        if (location) {
            const response = yield axiosLasta(`/client/access-personnel/location/${location.id}`);
            const { data } = response;

            data.forEach((el) => {
                const modifiedAuthorizedPerson = {
                    ...el,
                    id: el.authorizedPerson.id,
                    timestamp: `${el.created.slice(0, 10)} ${el.created.slice(11, 16)}`,
                    firstName: el.authorizedPerson.firstName,
                    lastName: el.authorizedPerson.lastName,
                    position: el.authorizedPerson.position,
                    phoneNumber: el.authorizedPerson.phoneNumber,
                    dl: el.authorizedPerson.dl,
                    cdl: el.authorizedPerson.cdl,
                    companyCardNumber: el.authorizedPerson.companyCardNumber,
                    alerts: el.authType?.color ? true : false,
                    authorizedBy: `${el.authorizedBy?.firstName || ''} ${el.authorizedBy?.lastName || ''}`,
                    authTypes: [],
                };
                modifiedAuthorizedList.push(modifiedAuthorizedPerson);
            });
        }

        const arrayHashmap = modifiedAuthorizedList.reduce((obj, item) => {
            if (obj[item.id]) {
                if (obj[item.id].authTypes.length === 0) {
                    obj[item.id].authTypes = [obj[item.id].authType, item.authType];
                } else {
                    obj[item.id].authTypes = [...obj[item.id].authTypes, item.authType];
                }
            } else {
                obj[item.id] = { ...item };
            }
            return obj;
        }, {});
        const mergedArray = Object.values(arrayHashmap);

        yield put(peopleAuthorizedListActions.fetchAuthorizedLocationSuccess(mergedArray));
    } catch (err) {
        yield put(peopleAuthorizedListActions.submitFormFailed(err));
        yield notificationsHandler({
            title: 'Error fetching authorized people',
            err,
        });
    }
}

export function* onFetchingAuthorizedPersonPerLocation() {
    yield takeEvery(peopleAuthorizedListActions.fetchAuthorizedPersonPerLocation.type, fetchAuthorizedPersonPerLocation);
}

export function* onSubmitFormStart() {
    yield takeLatest(peopleAuthorizedListActions.submitFormStart, FormSaga);
}

export function* fetchPerson() {
    try {
        const response = yield axiosLasta(`/client/personnel/all`);
        const { data } = response;
        data.sort(function (a, b) {
            var textA = a?.firstName?.toUpperCase();
            var textB = b?.firstName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        const adjustedData = data.map((el) => {
            return { ...el, fullName: `${el.firstName} ${el.lastName}` };
        });

        yield put(peopleAuthorizedListActions.fetchPersonSuccess(adjustedData));
    } catch (err) {
        yield put(peopleAuthorizedListActions.fetchPersonError(err));
        yield notificationsHandler({ title: 'Error fetching people', err });
    }
}

export function* onFetchingPersonsStart() {
    yield takeEvery(peopleAuthorizedListActions.fetchPersonStart.type, fetchPerson);
}

export function* onAddPerson() {
    yield takeLatest(peopleAuthorizedListActions.addPerson.type, FormSaga);
}
export function* onAddSelectedPersonToAuthorizedList() {
    yield takeLatest(peopleAuthorizedListActions.addAuthorizedPerson.type, FormSaga);
}

export function* fetchAllAuthTypes({ payload: locationId }) {
    try {
        if (locationId) {
            const response = yield axiosLasta('/client/authorization-type/all');
            const { data } = response;
            yield put(peopleAuthorizedListActions.setAuthTypeList(data));
        }
    } catch (err) {
        yield notificationsHandler({
            err,
            title: 'Error fetching authorization types',
        });
    }
}

export function* onFetchAllAuthTypes() {
    yield takeLatest(peopleAuthorizedListActions.fetchAuthTypeList, fetchAllAuthTypes);
}

export function* fetchPersonAuthTypes({ payload: dataPayload }) {
    try {
        if (dataPayload.personId) {
            const response = yield axiosLasta(
                `/client/authorization-type/authorized-person/${dataPayload.personId}/location/${dataPayload.locationId}`
            );
            const { data } = response;
            yield put(peopleAuthorizedListActions.fetchAuthorizedPersonAlerts(data));
        }
    } catch (err) {
        yield notificationsHandler({
            title: 'Error fetching authorization types for person',
            err,
        });
    }
}

export function* onFetchPersonAuthTypes() {
    yield takeEvery(peopleAuthorizedListActions.fetchPersonAuthTypesStart, fetchPersonAuthTypes);
}

export function* deleteAuthPerson({ payload: dataPayload }) {
    try {
        let message;
        const response = yield axiosLasta.delete(
            `/client/access-personnel/authorized-person/${dataPayload.personId}/location/${dataPayload.location.id}`
        );

        ({ message } = response.data);

        if (message.includes('SUCCESS')) {
            yield put(peopleAuthorizedListActions.fetchAuthorizedPersonPerLocation(dataPayload.location));
            yield put(peopleAuthorizedListActions.resetAuthPersonAlerts());
            yield put(peopleAuthorizedListActions.setSelectedAlert(null));
            yield notificationsHandler({
                title: 'Successfully removed person',
                variant: 'success',
            });
        }
    } catch (err) {
        yield notificationsHandler({ title: 'Error removing person', err });
    }
}

export function* onDeleteAuthPerson() {
    yield takeEvery(peopleAuthorizedListActions.deleteAuthPerson, deleteAuthPerson);
}

export function* deleteAuthType({ payload: dataPayload }) {
    try {
        let message;
        const getAuthTypeIds = {
            personId: dataPayload.personId,
            locationId: dataPayload.location.id,
        };
        const response = yield axiosLasta.delete(
            `/client/access-personnel/authorized-person/${dataPayload.personId}/location/${dataPayload.location.id}/auth-type/${dataPayload.authType.id}`
        );

        ({ message } = response.data);

        if (message.includes('SUCCESS')) {
            yield put(peopleAuthorizedListActions.fetchPersonAuthTypesStart(getAuthTypeIds));
            yield put(peopleAuthorizedListActions.fetchAuthorizedPersonPerLocation(dataPayload.location));
            yield notificationsHandler({
                title: 'Successfully removed authorization',
                variant: 'success',
            });
        }
    } catch (err) {
        yield notificationsHandler({
            title: 'Error removing authorization',
            err,
        });
    }
}

export function* onDeleteAuthType() {
    yield takeEvery(peopleAuthorizedListActions.deleteAuthType, deleteAuthType);
}
export function* peopleAuthorizedListSaga() {
    yield all([
        call(onFetchAllLocations),
        call(onFetchingAuthorizedPersonPerLocation),
        call(onSubmitFormStart),
        call(onFetchingPersonsStart),
        call(onAddPerson),
        call(onAddSelectedPersonToAuthorizedList),
        call(onFetchAllAuthTypes),
        call(onFetchPersonAuthTypes),
        call(onDeleteAuthPerson),
        call(onDeleteAuthType),
    ]);
}
