import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';

import { SnackbarProvider } from 'notistack';
import { snackBarGeneralConfiguration } from 'config';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'components/errorBoundaries/MainEB';
import Notifier from 'components/UI/notifications/Notifier';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <ErrorBoundary>
        <Provider store={store}>
            <SnackbarProvider
                maxSnack={snackBarGeneralConfiguration.maxSnack}
                autoHideDuration={snackBarGeneralConfiguration.autoHideDuration}
                preventDuplicate={true}
            >
                <App />
                <Notifier />
            </SnackbarProvider>
        </Provider>
    </ErrorBoundary>
    // </React.StrictMode>
);
