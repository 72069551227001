import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import styles from './AIQAutocomplete.module.css';
import Dropdown from './Dropdown/Dropdown';

// TODO: Add component prop-types.  Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function AIQAutocomplete({
    renderInput,
    options,
    getOptionLabel,
    onInputChange = () => {},
    onChange = () => {},
    onBlur = () => {},
    value,
    freeSolo,
    containerClasses,
    noOptionsText = 'No options...',
}) {
    const getInputValue = useCallback(
        (value) => {
            return getOptionLabel ? setOptionLabel(value, getOptionLabel) : (value ?? '');
        },
        [getOptionLabel]
    );

    const getOption = useCallback(
        (optionLabel) => {
            return getOptionLabel ? options.find((option) => getOptionLabel(option) === optionLabel) : optionLabel;
        },
        [getOptionLabel, options]
    );
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [inputValue, setInputValue] = useState(getInputValue(value));
    const [dropdownOpened, setDropdownOpened] = useState(false);

    useEffect(() => {
        setInputValue(getInputValue(value));
    }, [value, getOptionLabel, getInputValue]);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    function inputBlurHandler(e) {
        const inputText = e.target.value;
        if (inputValue === '' && inputText !== '' && getOptionLabel) {
            const option = getOption(inputText);
            if (option) {
                onChange(e, option);
                onBlur(e);
                return;
            }
        }
        const selectedOption = getOption(inputValue);
        if (!selectedOption) setInputValue(getInputValue(value));
        if (selectedOption) onChange(e, selectedOption);
        onBlur(e);
    }

    function focusHandler(focused) {
        setFilteredOptions(options);
        setDropdownOpened(focused);
    }

    function inputChangeHandler(e) {
        const inputText = e.target.value;
        setInputValue(inputText);
        onInputChange(e, inputText);

        setFilteredOptions(
            options.reduce((filteredOptions, option) => {
                const optionLabel = getInputValue(option);
                optionLabel.toLowerCase().includes(inputText.trim().toLowerCase()) && filteredOptions.push(option);

                return filteredOptions;
            }, [])
        );
    }

    return (
        <div className={`${styles['autocomplete-container']}${containerClasses ? ` ${containerClasses}` : ''}`}>
            {renderInput({
                value: inputValue,
                onChange: inputChangeHandler,
                onBlur: inputBlurHandler,
                onFocus: focusHandler,
                dropdownArrow: true,
            })}
            <Dropdown
                {...{
                    dropdownOpened,
                    filteredOptions,
                    noOptionsText,
                    onChange,
                    setDropdownOpened,
                    setInputValue,
                    freeSolo,
                    getInputValue,
                    value,
                }}
            />
        </div>
    );
}

export default AIQAutocomplete;

function setOptionLabel(option, getOptionLabel) {
    if (option) return typeof option === 'object' ? (getOptionLabel ? getOptionLabel(option) : option) : option;

    return '';
}
