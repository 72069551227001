import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    locationValues: [],
    cameraValues: [],
    selectedLocationRow: null,
    selectedCameraRow: null,
    modalState: false,
    buttonState: false,
    deployStatusMessages: [],
    stateStatusMessages: [],
};

const controlCentarList = createSlice({
    name: 'controlCentarList',
    initialState,
    reducers: {
        fetchTableValues() {},
        fetchModalTableValues() {},
        setLocationValues(state, { payload: locationValues }) {
            state.locationValues = locationValues;
        },
        setCamerasValues(state, { payload: locationValues }) {
            state.cameraValues = locationValues;
        },
        setSelectedLocationRow(state, { payload: cameraId }) {
            state.selectedLocationRow = [...state.locationValues].find((camera) => camera.id === cameraId);
        },
        setSelectedCameraRow(state, { payload: cameraId }) {
            state.selectedCameraRow = [...state.cameraValues].find((camera) => camera.id === cameraId);
        },
        setModalState(state, { payload: modalState }) {
            state.modalState = modalState;
        },

        setDeploymentCameraStages(state, { payload: message }) {
            state.deployStatusMessages = [message, ...state.deployStatusMessages];
            state.cameraValues = [...state.cameraValues].map((el) => {
                if (el.id === message.id) {
                    el.deploymentState.stages = {
                        ...el.deploymentState.stages,
                        ...message.deploymentState.stages,
                    };
                }
                return el;
            });
        },
        setStatusCameraStages(state, { payload: message }) {
            state.deployStatusMessages = [message, ...state.deployStatusMessages];
            state.cameraValues = [...state.cameraValues].map((el) => {
                if (el.id === message.id) {
                    el.status = message.status;
                }
                return el;
            });
        },
        setDeploymentLocationStages(state, { payload: message }) {
            state.deployStatusMessages = [message, ...state.deployStatusMessages];
            state.locationValues = [...state.locationValues].map((el) => {
                if (el.id === message.id) {
                    el.deploymentState.stages = {
                        ...el.deploymentState.stages,
                        ...message.deploymentState.stages,
                    };
                    state.selectedLocationRow.deploymentState.stages = {
                        ...el.deploymentState.stages,
                        ...message.deploymentState.stages,
                    };
                }
                return el;
            });
        },
        setStatusLocationStages(state, { payload: message }) {
            state.deployStatusMessages = [message, ...state.deployStatusMessages];
            state.locationValues = [...state.locationValues].map((el) => {
                if (el.id === message.id) {
                    el.streamStatus = message.status;
                    state.selectedLocationRow.streamStatus = message.status;
                }
                return el;
            });
        },
    },
});

export const controlCentarActions = controlCentarList.actions;
export const controlCentarReducer = controlCentarList.reducer;
