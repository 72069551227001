export const tableColumnsConfig = [
    {
        columns: [
            {
                caption: 'Check',
                dataField: 'activeStatus',
                dataType: 'boolean',
                editable: false,
                fixed: true,
            },
            {
                caption: 'ID',
                dataField: 'id',
                dataType: 'string',
                editable: false,
                fixed: true,
            },
            {
                caption: 'Location',
                dataField: 'locationName',
                dataType: 'string',
                editable: false,
                fixed: true,
            },
            {
                caption: 'Name',
                dataField: 'name',
                dataType: 'string',
                editable: false,
                fixed: true,
            },
        ],
    },
    {
        caption: 'Status',
        columns: [
            {
                caption: 'Last Successful Update',
                dataField: 'lastSuccessfulUpdate',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Last Update',
                dataField: 'lastUpdate',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Last Update Status',
                dataField: 'lastUpdateStatus',
                dataType: 'boolean',
                editable: false,
            },
        ],
    },
    {
        caption: 'Device',
        columns: [
            {
                caption: 'Device ID',
                dataField: 'deviceId',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Username',
                dataField: 'username',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'IP Address',
                dataField: 'ipv4Address',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Model',
                dataField: 'model',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Mac Address',
                dataField: 'macAddress',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Firmware Version',
                dataField: 'firmwareVersion',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Encoder Version',
                dataField: 'encoderVersion',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Audio',
        columns: [
            {
                caption: 'Compression Type',
                dataField: 'audioCompressionType',
                dataType: 'string',
                editable: false,
                width: 130,
            },
            {
                caption: 'Audio Enabled',
                dataField: 'audioEnabled',
                dataType: 'string',
                editable: false,
                width: 110,
            },
        ],
    },
    {
        caption: 'Stream Configuration',
        columns: [
            {
                caption: 'Resolution',
                dataField: 'streamResolution',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Bitrate',
                dataField: 'streamBitrate',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Key Frame Interval',
                dataField: 'streamKeyFrameInterval',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Max Frame Rate',
                dataField: 'streamFrameRate',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Codec Type',
                dataField: 'streamCodecType',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Codec+',
                dataField: 'streamCodecPlus',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'HTTP Port',
                dataField: 'httpPort',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'RTSP Port',
                dataField: 'rtspPort',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Online Users',
                dataField: 'onlineUsers',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Stream Source',
                dataField: 'streamSourceMain',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Multicast Enabled',
                dataField: 'multicastEnabled',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Stream Audio PCMmulaw (FFProbe)',
        columns: [
            {
                caption: 'Camera URL',
                dataField: 'urlStreamQualityMainAudio',
                dataType: 'string',
                editable: false,
                width: 100,
            },
            {
                caption: 'NVR URL',
                dataField: 'urlNvrStreamQualityMainAudio',
                dataType: 'string',
                editable: false,
                width: 100,
            },
            {
                caption: 'MMTX URL',
                dataField: 'urlProxyStreamQualityMainAudio',
                dataType: 'string',
                editable: false,
                width: 100,
            },
            {
                caption: 'Sub MMTX URL',
                dataField: 'urlProxyStreamQualitySubAudio',
                dataType: 'string',
                editable: false,
                width: 120,
            },
        ],
    },
    {
        caption: 'Stream Video H.264 (FFProbe)',
        columns: [
            {
                caption: 'Camera URL',
                dataField: 'urlStreamQualityMainVideo',
                dataType: 'string',
                editable: false,
                width: 100,
            },
            {
                caption: 'NVR URL',
                dataField: 'urlNvrStreamQualityMainVideo',
                dataType: 'string',
                editable: false,
                width: 100,
            },
            {
                caption: 'MMTX URL',
                dataField: 'urlProxyStreamQualityMainVideo',
                dataType: 'string',
                editable: false,
                width: 100,
            },
            {
                caption: 'Sub MMTX URL',
                dataField: 'urlProxyStreamQualitySubVideo',
                dataType: 'string',
                editable: false,
                width: 120,
            },
        ],
    },
    {
        caption: 'Stream URLs (FFProbe)',
        columns: [
            {
                caption: 'Main Stream Camera URL',
                dataField: 'urlStreamQualityMain',
                dataType: 'string',
                editable: false,
                width: 350,
            },
            {
                caption: 'Sub Stream Camera URL',
                dataField: 'urlStreamQualitySub',
                dataType: 'string',
                editable: false,
                width: 320,
            },
            {
                caption: 'Main Stream NVR URL',
                dataField: 'urlNvrStreamQualityMain',
                dataType: 'string',
                editable: false,
                width: 350,
            },
            {
                caption: 'Sub Stream NVR URL',
                dataField: 'urlNvrStreamQualitySub',
                dataType: 'string',
                editable: false,
                width: 350,
            },
            {
                caption: 'Main Stream MMTX URL',
                dataField: 'urlProxyStreamQualityMain',
                dataType: 'string',
                editable: false,
                width: 300,
            },
            {
                caption: 'Sub Stream MMTX URL',
                dataField: 'urlProxyStreamQualitySub',
                dataType: 'string',
                editable: false,
                width: 300,
            },
        ],
    },
];
