export const ROLES = {
    ADMIN: 'admin',
    AGENT: 'agent',
    USER: 'user',
    HR: 'hr',
    HQ_SUPPORT: 'hq_support',
    FACILITY_MANAGER: 'facility_manager',
    SALES: 'sales',
    TECH: 'tech',
};
