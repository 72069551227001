import styles from './Automation.module.css';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function Automation({ disabled, automation }) {
    const { source, info, sourceValue } = automation || {};

    return (
        <div className={`${styles['automation-container']}${disabled ? ` ${styles['automation-container--disabled']}` : ''}`}>
            <div className={styles['automation-container--source']}>
                <p>{source}</p>
                <p>{info}</p>
            </div>
            <div className={styles['automation-container--value']}>
                <p>{sourceValue}</p>
            </div>
        </div>
    );
}

export default Automation;
