import styles from './Alerts.module.css';

import Alert from './Alert/Alert';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function Alerts({ alerts, containerClasses }) {
    const classes = `${styles['alert-container']}${containerClasses ? ` ${containerClasses}` : ''}`;

    return (
        <>
            {alerts && !!alerts.length && (
                <div className={classes}>
                    {alerts.map(({ text, color }) => {
                        return <Alert key={`${text}${color}`} text={text} backgroundColor={color} />;
                    })}
                </div>
            )}
        </>
    );
}

export default Alerts;
