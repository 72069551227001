import { useDispatch, useSelector } from 'react-redux';
import { fetchLocationsStart, setSelectedLocationId } from '../store/globalLocationSlice';
import {
    selectFetchingLocationsError,
    selectFetchingLocationsInProgress,
    selectLocationsList,
    selectSelectedLocationId,
} from 'components/globalLocationSelector/store/globalLocationSelectors.js';
import { useCallback } from 'react';

const useGlobalLocation = () => {
    const dispatch = useDispatch();

    const dispatchFetchLocations = useCallback(() => {
        dispatch(fetchLocationsStart());
    }, [dispatch]);

    const dispatchSetSelectLocationId = useCallback(
        (locationId) => {
            dispatch(setSelectedLocationId(locationId));
        },
        [dispatch]
    );

    const fetchingLocationsInProgress = useSelector(selectFetchingLocationsInProgress);
    const fetchingLocationsError = useSelector(selectFetchingLocationsError);
    const locationsList = useSelector(selectLocationsList);
    const selectedLocationId = useSelector(selectSelectedLocationId);

    return {
        fetchLocations: dispatchFetchLocations,
        setSelectLocationId: dispatchSetSelectLocationId,
        fetchingLocationsInProgress,
        fetchingLocationsError,
        locationsList,
        selectedLocationId,
    };
};

export default useGlobalLocation;
