import { snackbarActions } from 'store/snackbars/snackbarsSlice';
import { ALERT_SERVICE_WEBSOKET } from 'config';
import { getCookie } from 'utils/utils';
import { PROFILE } from 'config';
import store from 'store/store';
import { Client } from '@stomp/stompjs';

export const webSocketGeneralConfig = {
    timesToTryReconnect: 30,
    reconnectDelay: 1000,
};

export const stompClientConfig = {
    brokerURL: ALERT_SERVICE_WEBSOKET,
    connectHeaders: {},
    // debug: function (str) {
    //     if (str !== 'Received data' && str !== '<<< PONG' && str !== '>>> PING') console.log(str);
    // },

    reconnectDelay: webSocketGeneralConfig.reconnectDelay, //in case ws connection fails or disconected, recconection will be tried [reconnectDelay]ms. Seems to be infinite loop of retries
    connectionTimeout: 30000, //it may occur that connection is succesful, but we never get back response on 'CONNECT' frame sent, in that case after [connectionTimeout]ms ws connection is closed and  new reconnect will be scheduled in [reconnectDelay]ms. Otherwise (without this property) initial ws connection remains open and waits forever.
    heartbeatIncoming: 60000,
    heartbeatOutgoing: 60000,
};

class AlertService {
    constructor(config) {
        this.counter = 0;
        this.alertClient = new Client(config);
    }

    showChromeNotification(alert) {
        const time = `${alert.startsAt?.slice(0, 10)} ${alert.startsAt?.slice(11, 16)}`;
        const notification = new Notification(
            `${alert.name} service is ${alert.status === 'firing' ? 'down' : 'up'}, instance ${alert.instance} ${
                alert.startsAt ? `, since ${time}` : ''
            }`
        );
    }

    showNotifications(message, alertCookie) {
        const newAlert = JSON.parse(message.body);
        const time = `${newAlert.startsAt?.slice(0, 10)} ${newAlert.startsAt?.slice(11, 16)}`;
        store.dispatch(
            snackbarActions.enqueueSnackbar({
                message: `${newAlert.name} service is ${newAlert.status === 'firing' ? 'down' : 'up'}, instance ${newAlert.instance}${
                    newAlert.startsAt ? `, since ${time}` : ''
                }`,
                options: {
                    variant: `${newAlert.status === 'firing' ? 'error' : 'success'}`,
                },
            })
        );
        Notification.requestPermission();
        if (Notification.permission === 'granted' && alertCookie) {
            this.showChromeNotification(newAlert);
            return;
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted' && alertCookie) {
                    this.showChromeNotification(newAlert);
                }
            });
        }
    }

    activate() {
        this.alertClient.activate();
        let cookieLasta;

        switch (PROFILE) {
            case 'development':
                cookieLasta = getCookie('lastaD');
                break;
            case 'staging':
                cookieLasta = getCookie('lastaS');
                break;
            case 'production':
                cookieLasta = getCookie('lastaP');
                break;
            default:
                cookieLasta = getCookie('lasta');
        }

        this.alertClient.onConnect = () => {
            this.alertClient.subscribe('/user/topic/all', (message) => {
                this.showNotifications(message, cookieLasta);
            });

            this.alertClient.subscribe('/topic/all', (message) => {
                this.showNotifications(message, cookieLasta);
            });
        };
    }

    deactivate() {
        this.alertClient.deactivate();
        document.cookie = 'alertName=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    onWebSocketError() {
        this.alertClient.onWebSocketError = function () {
            this.counter++;

            if (this.counter === webSocketGeneralConfig.timesToTryReconnect) {
                store.dispatch(
                    snackbarActions.enqueueSnackbar({
                        message: `WebSocket Alert Service error`,
                        options: { variant: 'error' },
                    })
                );
                this.counter = 0;
            }
        };
    }

    onStompError() {
        this.alertClient.onStompError = function () {
            store.dispatch(
                snackbarActions.enqueueSnackbar({
                    message: `Stomp Alert Service error`,
                    options: { variant: 'error' },
                })
            );
        };
    }
}

export const alertService = new AlertService(stompClientConfig);
