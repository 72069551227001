import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth/authSelectors.js';
import { StompSessionProvider } from 'react-stomp-hooks';
import { CONTROL_CENTAR_WEBSOKET } from 'config';
import NavigationDrawer from 'components/navigation/NavigationDrawer.jsx';
import { Suspense } from 'react';
import CircularLoader from 'components/UI/loaders/CircularLoader.jsx';
import { Outlet } from 'react-router-dom';

const AppLayout = () => {
    const user = useSelector(selectUser);
    return (
        <StompSessionProvider url={CONTROL_CENTAR_WEBSOKET}>
            {user && <NavigationDrawer user={user} />}
            <Suspense fallback={<CircularLoader />}>
                <Outlet />
            </Suspense>
        </StompSessionProvider>
    );
};

export default AppLayout;
