import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import notificationsHandler from 'utils/notificationsHandler';
import { monitoringElementActions } from './monitoringElementSlice';
import { mainConfig } from 'features/monitoringElements/table/tableConfig';
import { activeMonitoringElementFamilyId } from 'store/MonitoringElement/monitoringElementSelectors';
import { tableControlActions } from 'store/tableControlSlice/tableControlSlice';

import { axiosLasta } from 'config';

export function* fetchDropDownValues(payload) {
    try {
        const response = yield axiosLasta(payload.payload.urlPath);
        const { data } = response;
        const payloadData = {
            dataSource: data,
            fieldName: payload.payload.fieldName,
        };
        yield put(monitoringElementActions.setDropDownValues(payloadData));
    } catch (err) {
        if (err?.response?.status === 401) return;
        yield notificationsHandler(`Error getting field values, ${err.message?.toUpperCase()}`, {
            variant: 'error',
        });
    }
}

export function* getSingleMonitoringElement({ payload }) {
    try {
        const response = yield axiosLasta(`monitoring/element/${payload}`);
        const { data } = response;
        if (data.monitoringObject) {
            data.monitoringObject['monitoringName'] = `${data.monitoringObject.name.trim()} (${data.monitoringObject.objectType.trim()})`;
        }
        yield put(monitoringElementActions.setSingleMonitoringElement(data));
    } catch (err) {
        yield notificationsHandler(`Error getting monitoring element, ${err.message?.toUpperCase()}`, {
            variant: 'error',
        });
    }
}

export function* deleteMonitoringObject({ payload: id }) {
    try {
        const monitoringElementFamilyId = yield select(activeMonitoringElementFamilyId);
        const response = yield axiosLasta.delete(`/monitoring/element/id/${id}`);

        if (response.status === 200) {
            yield put(
                tableControlActions.fetchTableDataStart({
                    tableId: mainConfig.tableId,
                    urlPath: getTableDataUrl(monitoringElementFamilyId),
                    fetchTableConfig: false,
                })
            );
            yield put(monitoringElementActions.setRemoveModalState(false));
            yield notificationsHandler({
                title: 'Monitoring element deleted',
                variant: 'success',
            });
        }
    } catch (err) {
        yield notificationsHandler(`Error deleting monitoring object`, {
            variant: 'error',
        });
    }
}

export function* onSetSingleMonitoringElement() {
    yield takeEvery(monitoringElementActions.getSingleMonitoringElement.type, getSingleMonitoringElement);
}
export function* onDeleteMonitoringObject() {
    yield takeEvery(monitoringElementActions.deleteMonitoringObject.type, deleteMonitoringObject);
}

export function* onFetchDropDownValues() {
    yield takeEvery(monitoringElementActions.fetchDropDownValues.type, fetchDropDownValues);
}

export function* monitoringElementSaga() {
    yield all([call(onFetchDropDownValues), call(onDeleteMonitoringObject), call(onSetSingleMonitoringElement)]);
}

function getTableDataUrl(activeMonitoringElementFamilyId) {
    return activeMonitoringElementFamilyId === 'all' ? 'monitoring/element/all' : `monitoring/elements/family/${activeMonitoringElementFamilyId}`;
}
