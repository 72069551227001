import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function LastaDialog({ title, open, onClose = () => {}, children, fullScreen, classes, sxDialog, sxCloseButton }) {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            fullScreen={fullScreen}
            className={classes}
            sx={{
                width: '100vw',
                '& [class*=MuiPaper-root]': {
                    // height: 'max-content',
                    maxWidth: 'max-content',
                    ...sxDialog,
                },
            }}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    ...sxCloseButton,
                }}
            >
                <CloseIcon />
            </IconButton>
            {title && <DialogTitle sx={{ marginRight: '2rem' }}>{title}</DialogTitle>}
            {children}
        </Dialog>
    );
}

export default LastaDialog;
