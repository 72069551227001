import React from 'react';
import styles from './userNavigation.module.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import UserListDropDown from '../userList/UserListDropDown';
import PropTypes from 'prop-types';

export default function UserNavigation({ user }) {
    return (
        <div className={styles.userNavigation}>
            <NotificationsNoneIcon />
            <HelpOutlineIcon />
            <div>
                <p className={styles.userName}>Hi {user?.username}</p>
            </div>
            <UserListDropDown />
        </div>
    );
}

UserNavigation.propTypes = {
    user: PropTypes.object,
};
