import { ROLES } from 'utils/constants';

export const routes = [
    {
        allowedRoles: [ROLES.ADMIN, ROLES.HR, ROLES.AGENT, ROLES.HQ_SUPPORT, ROLES.USER, ROLES.TECH, ROLES.SALES],
        path: '/',
        text: 'Homepage',
    },
    {
        allowedRoles: [ROLES.FACILITY_MANAGER],
        path: '/access-point-records',
        text: 'Access Point Records',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.HR, ROLES.USER],
        path: '/employees',
        text: 'Employees',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.AGENT],
        path: '/people-authorized-list',
        text: 'Location’s Authorized People',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.AGENT],
        path: '/company-authorized-list',
        text: 'Location’s Authorized Companies',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/monitoring-elements',
        text: 'Monitoring Elements',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/organization-list',
        text: 'Organizations',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/location-list',
        text: 'Locations',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/divison-list',
        text: 'Divisions',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/camera-tron',
        text: 'CameraTron',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/control-center',
        text: 'Control Center',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/monitoring-object',
        text: 'Monitoring Object',
    },

    {
        text: 'Alisa',
        items: [{ path: '/regions-of-interest', text: 'Regions Of Interest' }],
    },
];
