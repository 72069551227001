import { all, call, select, put, takeEvery } from 'redux-saga/effects';
import { axiosLasta } from 'config';
import notificationsHandler from 'utils/notificationsHandler';
import { cameraInfoActions } from './cameraInfoSlice';
import { selectCamerasStatus } from './cameraInfoSelectors';

export function* fetchAllLocations() {
    try {
        const response = yield axiosLasta('/monitoring/element/camera-settings/locations/all');
        const { data } = response;
        data.sort(function (a, b) {
            var textA = a?.yardName?.toUpperCase();
            var textB = b?.yardName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        yield put(cameraInfoActions.setAllLocations(data));
    } catch (err) {
        yield notificationsHandler({ err, title: 'Error fetching locations' });
    }
}

export function* onFetchAllLocations() {
    yield takeEvery(cameraInfoActions.fetchAllLocations.type, fetchAllLocations);
}
export function* fetchAllCameraInfo() {
    try {
        const response = yield axiosLasta(`/monitoring/element/camera-settings/all`);
        const { data } = response;
        const previousCameraStatus = yield select(selectCamerasStatus);

        data.sort(function (a, b) {
            var textA = a?.locationName?.toUpperCase();
            var textB = b?.locationName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        data.map((el) => {
            if (el.lastSuccessfulUpdate) el.lastSuccessfulUpdate = `${el.lastSuccessfulUpdate.slice(0, 10)} ${el.lastSuccessfulUpdate.slice(11, 16)}`;
            if (el.lastUpdate) el.lastUpdate = `${el.lastUpdate.slice(0, 10)} ${el.lastUpdate.slice(11, 16)}`;
            return el;
        });
        yield put(cameraInfoActions.fetchCameraInfoSuccess(data));
        yield put(cameraInfoActions.refreshCameraStatus(previousCameraStatus));
    } catch (err) {
        yield notificationsHandler({
            title: 'Error fetching all camera information',
        });
    }
}

export function* fetchCameraInfo({ payload: id }) {
    try {
        const response = yield axiosLasta(`/monitoring/element/camera-settings/${id}`);
        const { data } = response;
        const previousCameraStatus = yield select(selectCamerasStatus);

        if (response.status === 200) {
            if (data.lastSuccessfulUpdate) {
                data.lastSuccessfulUpdate = `${data.lastSuccessfulUpdate.slice(0, 10)} ${data.lastSuccessfulUpdate.slice(11, 16)}`;
            }

            if (data.lastUpdate) {
                data.lastUpdate = `${data.lastUpdate.slice(0, 10)} ${data.lastUpdate.slice(11, 16)}`;
            }

            yield put(cameraInfoActions.fetchSingleCameraSuccess(data));
            yield put(cameraInfoActions.refreshCameraStatus(previousCameraStatus));
        }
    } catch (err) {
        yield notificationsHandler({
            title: `Error fetching camera information`,
        });
    }
}

export function* refreshCameraInfoPerLocation({ payload: location }) {
    try {
        yield put(cameraInfoActions.setRefreshLocationInProgress(true));
        const previousCameraStatus = yield select(selectCamerasStatus);
        const response = yield axiosLasta.post(`/monitoring/element/camera-settings/location/${location.id}`);
        let message = response.data.message;
        message = message.substring(message.indexOf('[') + 1);
        message = message.substring(0, message.indexOf(']'));

        if (response.status === 200) {
            yield put(cameraInfoActions.fetchAllCameraInfo());
            yield put(cameraInfoActions.setLastRunStatus(message));
            const responseStatus = yield axiosLasta.get(`/validate-cameras/location/${location.id}`);
            const data = responseStatus.data;
            yield put(
                cameraInfoActions.refreshCameraStatus({
                    ...previousCameraStatus,
                    ...data,
                })
            );
            yield notificationsHandler({
                title: `${message}`,
                variant: 'success',
            });
            yield notificationsHandler({
                title: `started validation for location with id:${location.id}`,
                variant: 'success',
            });
            yield put(cameraInfoActions.setRefreshLocationInProgress(false));
        }
    } catch (err) {
        if (err.request.status === 409) {
            yield notificationsHandler({ title: err.response.data });
        } else {
            yield notificationsHandler({
                title: `Error refreshing camera information for location with id: ${location.id}`,
            });
        }
        yield put(cameraInfoActions.setLastRunStatus('UNSUCCESSFULL'));
        yield put(cameraInfoActions.setRefreshLocationInProgress(false));
        yield put(cameraInfoActions.setSelectedLocation(null));
    }
}

export function* refreshCameraInfo({ payload: camera }) {
    try {
        yield put(cameraInfoActions.setRefreshSingleCameraInProgress(true));
        const response = yield axiosLasta.post(`/monitoring/element/camera-settings/${camera.id}`);
        let message = response.data.message;
        message = message.substring(message.indexOf('[') + 1);
        message = message.substring(0, message.indexOf(']'));

        if (response.status === 200) {
            yield put(cameraInfoActions.fetchCameraInfo(camera.id));
            yield put(cameraInfoActions.setLastRunStatus(message));
            const responseStatus = yield axiosLasta.get(`/validate-camera/${camera.id}`);
            const data = responseStatus.data;
            const previousCameraStatus = yield select(selectCamerasStatus);

            yield put(
                cameraInfoActions.refreshCameraStatus({
                    ...previousCameraStatus,
                    ...data,
                })
            );
            yield notificationsHandler({
                title: `${message}`,
                variant: message === 'SUCCESS' ? 'success' : 'error',
            });
            yield put(cameraInfoActions.setRefreshSingleCameraInProgress(false));
        }
    } catch (err) {
        if (err.request?.status === 409) {
            yield notificationsHandler({ title: err.response.data });
        } else {
            yield notificationsHandler({
                title: `Error refreshing camera information for ${camera.name} camera with id: ${camera.id}, ${err.message}`,
            });
        }

        yield put(cameraInfoActions.setLastRunStatus('UNSUCCESSFULL'));
        yield put(cameraInfoActions.setRefreshSingleCameraInProgress(false));
        yield put(cameraInfoActions.setSelectedCameraRefresh(null));
    }
}

export function* onRefreshCameraInfo() {
    yield takeEvery(cameraInfoActions.refreshCameraInfo.type, refreshCameraInfo);
}

export function* onRefreshCameraInfoPerLocation() {
    yield takeEvery(cameraInfoActions.refreshCameraInfoPerLocation.type, refreshCameraInfoPerLocation);
}

export function* onfetchAllCameraInfo() {
    yield takeEvery(cameraInfoActions.fetchAllCameraInfo.type, fetchAllCameraInfo);
}

export function* onfetchCameraInfo() {
    yield takeEvery(cameraInfoActions.fetchCameraInfo.type, fetchCameraInfo);
}

export function* cameraInfoSaga() {
    yield all([
        call(onfetchAllCameraInfo),
        call(onfetchCameraInfo),
        call(onRefreshCameraInfo),
        call(onRefreshCameraInfoPerLocation),
        call(onFetchAllLocations),
    ]);
}
