import { Navigate, Outlet } from 'react-router-dom';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function ProtectedRoute({ isAllowed, redirectPath = '/', children }) {
    if (!isAllowed) return <Navigate to={redirectPath} replace />;

    return children ? children : <Outlet />;
}

export default ProtectedRoute;
