import { useRouteHandleData } from 'components/appRoute/hooks/useRouteHandleData.js';

export const useTopNavRouteData = () => {
    const routeData = useRouteHandleData();
    const { topNavMiddleAdornment } = routeData || {};

    return {
        topNavMiddleAdornment,
    };
};
