import { all, call, put, takeEvery } from 'redux-saga/effects';
import notificationsHandler from 'utils/notificationsHandler';
import { monitoringObjectActions } from './monitoringObjectSlice';
import { FormSaga } from 'store/formControl/formMonitoringObjectSaga';
import { axiosLasta } from 'config';

export function* fetchTableValues({ payload: id }) {
    try {
        const response = yield axiosLasta(`/monitoring/object/${id.id}`);
        const { data } = response;

        yield put(monitoringObjectActions.setTableValues(data));
    } catch (err) {
        yield notificationsHandler({
            title: `Error fetching monitoring objects`,
        });
    }
}

export function* onfetchTableValues() {
    yield takeEvery(monitoringObjectActions.fetchTableValues.type, fetchTableValues);
}

export function* fetchLocations() {
    try {
        const response = yield axiosLasta(`/location/all`);
        const { data } = response;
        yield put(monitoringObjectActions.setFetchedType(data));
    } catch (err) {
        yield notificationsHandler({ title: `Error fetching locations` });
    }
}

export function* onFetchLocations() {
    yield takeEvery(monitoringObjectActions.fetchLocation.type, fetchLocations);
}

export function* fetchSingleMonitoringObject({ payload }) {
    try {
        const response = yield axiosLasta(`/monitoring/object/id/${payload}`);
        const { data } = response;
        yield put(monitoringObjectActions.setSingleMonitoringObject(data));
    } catch (err) {
        yield notificationsHandler({ title: `Error fetching locations` });
    }
}

export function* onFetchSingleMonitoringObject() {
    yield takeEvery(monitoringObjectActions.fetchSingleMonitoringObject.type, fetchSingleMonitoringObject);
}

export function* onSubmitFormStart() {
    yield takeEvery(monitoringObjectActions.submitFormStart.type, FormSaga);
}

export function* monitoringObjectSaga() {
    yield all([call(onfetchTableValues), call(onFetchLocations), call(onSubmitFormStart), call(onFetchSingleMonitoringObject)]);
}
