import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import notificationsHandler from 'utils/notificationsHandler';
import { axiosLasta } from 'config';
import { companyAuthorizationListActions } from './companyAuthorizationListSlice';
import { FormCompanySaga } from 'store/formControl/formCompanySaga';

export function* fetchAllLocations() {
    try {
        const response = yield axiosLasta('/location/all');
        const { data } = response;
        data.sort(function (a, b) {
            var textA = a?.yardName?.toUpperCase();
            var textB = b?.yardName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        yield put(companyAuthorizationListActions.setAllLocations(data));
    } catch (err) {
        yield notificationsHandler({ err, title: 'Error fetching locations' });
    }
}

export function* onFetchAllLocations() {
    yield takeEvery(companyAuthorizationListActions.fetchAllLocations.type, fetchAllLocations);
}

export function* fetchAuthorizedListPerLocation({ payload: location }) {
    try {
        const modifiedAuthorizedList = [];
        if (location) {
            const response = yield axiosLasta(`/client/access-organization/location/${location.id}`);
            const { data } = response;

            data.forEach((el) => {
                const modifiedAuthorizedPerson = {
                    ...el,
                    id: el.authorizedOrganization.id,
                    timestamp: `${el.created.slice(0, 10)} ${el.created.slice(11, 16)}`,
                    name: el.authorizedOrganization.name,
                    clientType: el.authorizedOrganization.clientType,
                    commonName: el.authorizedOrganization.commonName,
                    industryType: el.authorizedOrganization.industryType,
                    position: el.authorizedOrganization.position,
                    phoneNumber: el.authorizedOrganization.phoneNumber,
                    alerts: el.authType?.color ? true : false,
                    authorizedBy: `${el.authorizedBy?.firstName || ''} ${el.authorizedBy?.lastName || ''}`,

                    authTypes: [],
                };
                modifiedAuthorizedList.push(modifiedAuthorizedPerson);
            });
        }

        const arrayHashmap = modifiedAuthorizedList.reduce((obj, item) => {
            if (obj[item.id]) {
                if (obj[item.id].authTypes.length === 0) {
                    obj[item.id].authTypes = [obj[item.id].authType, item.authType];
                } else {
                    obj[item.id].authTypes = [...obj[item.id].authTypes, item.authType];
                }
            } else {
                obj[item.id] = { ...item };
            }
            return obj;
        }, {});
        const mergedArray = Object.values(arrayHashmap);

        yield put(companyAuthorizationListActions.fetchAuthorizedLocationSuccess(mergedArray));
    } catch (err) {
        yield put(companyAuthorizationListActions.submitFormFailed(err));
        yield notificationsHandler({
            title: 'Error fetching authorized companies',
            err,
        });
    }
}

export function* onFetchingAuthorizedListPerLocation() {
    yield takeEvery(companyAuthorizationListActions.fetchAuthorizedListPerLocation.type, fetchAuthorizedListPerLocation);
}

export function* fetchMasterList() {
    try {
        const response = yield axiosLasta(`/organization/all`);
        const { data } = response;
        yield put(companyAuthorizationListActions.fetchMasterListSuccess(data));
    } catch (err) {
        yield notificationsHandler({
            title: 'Error fetching all companies',
            err,
        });
    }
}

export function* fetchPerson() {
    try {
        const response = yield axiosLasta(`/client/personnel/all`);
        const { data } = response;
        data.sort(function (a, b) {
            var textA = a?.firstName?.toUpperCase();
            var textB = b?.firstName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        const adjustedData = data.map((el) => {
            return { ...el, fullName: `${el.firstName} ${el.lastName}` };
        });

        yield put(companyAuthorizationListActions.fetchPersonSuccess(adjustedData));
    } catch (err) {
        yield put(companyAuthorizationListActions.fetchPersonError(err.message));
        yield notificationsHandler({ title: 'Error fetching people', err });
    }
}

export function* fetchState() {
    try {
        const response = yield axiosLasta(`/client/state/all`);
        const { data } = response;

        yield put(companyAuthorizationListActions.fetchStateSuccess(data));
    } catch (err) {
        yield put(companyAuthorizationListActions.fetchPersonError(err.message));
        yield notificationsHandler({ title: 'Error fetching state', err });
    }
}

export function* onFetchingStateStart() {
    yield takeLatest(companyAuthorizationListActions.fetchStateStart.type, fetchState);
}

export function* onFetchingPersonsStart() {
    yield takeEvery(companyAuthorizationListActions.fetchPersonStart.type, fetchPerson);
}

export function* onFetchingMasterListStart() {
    yield takeEvery(companyAuthorizationListActions.fetchMasterListStart.type, fetchMasterList);
}

export function* onSubmitFormStart() {
    yield takeLatest(companyAuthorizationListActions.submitFormStart, FormCompanySaga);
}

export function* onAddItemToMasterList() {
    yield takeLatest(companyAuthorizationListActions.addItemToMasterList.type, FormCompanySaga);
}
export function* onAddItemToAuthorizedList() {
    yield takeLatest(companyAuthorizationListActions.addItemToAuthorizedList.type, FormCompanySaga);
}

export function* fetchAllAuthTypes({ payload: locationId }) {
    try {
        if (locationId) {
            const response = yield axiosLasta('/client/authorization-type/all');
            const { data } = response;
            yield put(companyAuthorizationListActions.setAuthTypeList(data));
        }
    } catch (err) {
        yield notificationsHandler({
            err,
            title: 'Error fetching authorization types',
        });
    }
}

export function* onFetchAllAuthTypes() {
    yield takeLatest(companyAuthorizationListActions.fetchAuthTypeList, fetchAllAuthTypes);
}

export function* fetchAuthorizedItemAuthTypes({ payload: dataPayload }) {
    try {
        if (dataPayload.personId) {
            const response = yield axiosLasta(
                `/client/authorization-type/authorized-organization/${dataPayload.personId}/location/${dataPayload.locationId}`
            );
            const { data } = response;
            yield put(companyAuthorizationListActions.fetchAuthorizedItemAuthTypes(data));
        }
    } catch (err) {
        yield notificationsHandler({
            title: 'Error fetching authorization types',
            err,
        });
    }
}

export function* onFetchAuthorizedItemAuthTypes() {
    yield takeEvery(companyAuthorizationListActions.fetchAuthorizedItemAuthTypesStart, fetchAuthorizedItemAuthTypes);
}

export function* deleteAuthorizedItem({ payload: dataPayload }) {
    try {
        let message;
        const response = yield axiosLasta.delete(
            `/client/access-organization/authorized-organization/${dataPayload.personId}/location/${dataPayload.location.id}`
        );

        ({ message } = response.data);

        if (message.includes('SUCCESS')) {
            yield put(companyAuthorizationListActions.fetchAuthorizedListPerLocation(dataPayload.location));
            yield put(companyAuthorizationListActions.resetItemsAuthorizations());
            yield put(companyAuthorizationListActions.setSelectedAlert(null));
            yield notificationsHandler({
                title: 'Successfully removed company',
                variant: 'success',
            });
        }
    } catch (err) {
        yield notificationsHandler({ title: 'Error removing person', err });
    }
}

export function* onDeleteAuthorizedItem() {
    yield takeEvery(companyAuthorizationListActions.deleteAuthorizedItem, deleteAuthorizedItem);
}

export function* deleteAuthType({ payload: dataPayload }) {
    try {
        let message;
        const getAuthTypeIds = {
            personId: dataPayload.personId,
            locationId: dataPayload.location.id,
        };
        const response = yield axiosLasta.delete(
            `/client/access-organization/authorized-organization/${dataPayload.personId}/location/${dataPayload.location.id}/auth-type/${dataPayload.authType.id}`
        );

        ({ message } = response.data);

        if (message.includes('SUCCESS')) {
            yield put(companyAuthorizationListActions.fetchAuthorizedItemAuthTypesStart(getAuthTypeIds));
            yield put(companyAuthorizationListActions.fetchAuthorizedListPerLocation(dataPayload.location));
            yield notificationsHandler({
                title: 'Successfully removed authorization',
                variant: 'success',
            });
        }
    } catch (err) {
        yield notificationsHandler({
            title: 'Error removing authorization',
            err,
        });
    }
}

export function* onDeleteAuthType() {
    yield takeEvery(companyAuthorizationListActions.deleteAuthType, deleteAuthType);
}
export function* companyAuthorizationSaga() {
    yield all([
        call(onFetchAllLocations),
        call(onFetchingAuthorizedListPerLocation),
        call(onSubmitFormStart),
        call(onFetchingMasterListStart),
        call(onAddItemToMasterList),
        call(onAddItemToAuthorizedList),
        call(onFetchAllAuthTypes),
        call(onFetchAuthorizedItemAuthTypes),
        call(onDeleteAuthorizedItem),
        call(onDeleteAuthType),
        call(onFetchingPersonsStart),
        call(onFetchingStateStart),
    ]);
}
